import {
  shape,
  oneOf,
  node,
  number,
  arrayOf,
  string,
  bool,
  object,
  objectOf,
  oneOfType,
} from "prop-types";
import colors from "./colors";
import transitions from "../animation/transitions";
import { perDiseaseConfig } from "../../utils/perDiseaseConfig";

/**
 * Collection of re-usable propTypes objects for easy sharing / threading through
 * objects. Consider this a poor-man's React-only TypeScript
 */

export const supportedColorLabels = oneOf(Object.keys(colors));
export const supportedColorValues = oneOf(Object.values(colors));
export const supportedTransitions = oneOf(Object.keys(transitions));
export const supportedGenders = oneOf(["male", "female"]);
export const supportedDiseaseKeys = oneOf(Object.keys(perDiseaseConfig));
export const supportedRangeStyles = oneOf(["extraThin", "thick", "thin"]);
export const supportedReportStyles = oneOf(["individual", "joint", "embryo"]);

// A marker that indicates a single point on a range or curve
export const marker = {
  // Color of the marker itself, label is assumed to control it's own color
  color: string.isRequired,
  // Float percent offset from 0 on the X-axis
  position: number.isRequired,
  // This will be a self-contained component
  label: node,
  // Whether to offset the label an additional amount on the y-axis to avoid overlap
  offset: bool,
  // Size of the label text. Defaults to medium size
  size: string,
  // Whether the marker should extend additional pixels
  // rather than clamping to the top of the graph
  tall: bool,
  // Whether to include a border around the marker, if present a 1px border will be added
  // of the provided color
  border: string,
  // How tall the text in the marker is
  textHeight: number,
  labelProps: object,
};

// An icon is any render-able SVG image.
export const icon = {
  name: string.isRequired,
  props: object,
};

// A range of values drawn as a single line
export const range = {
  // Float percent offset from 0 on the X-axis
  start: number.isRequired,
  // Float percent offset from 0 on the X-axis
  end: number.isRequired,
  // Text to be displayed above range
  label: string,
  // Icons that may be used to decorate the beginning and end of the range.
  startIcon: shape(icon),
  endIcon: shape(icon),
  // Color of the range
  color: supportedColorLabels.isRequired,
  // Style of the range. Defaults to "thin" generally
  style: supportedRangeStyles,
};

// Single key in a legend
export const legendKey = shape({
  label: string.isRequired,
  // Image URL or other identifier TBD
  icon: shape(icon).isRequired,
}).isRequired;

// A legend that may sit above or below a chart or set of charts
// It consists of one or more legend entries, which is an icon and some
// text.
export const legend = {
  keys: arrayOf(legendKey),
};

// A section of a graph that is colored differently
export const segment = {
  // Percent X-axis offset
  start: number.isRequired,
  end: number.isRequired,
  color: supportedColorLabels.isRequired,
  transition: supportedTransitions,
};

export const intersectionDot = {
  // X-axis offset, in float percent
  position: number.isRequired,
  // height: number.isRequired,
  color: supportedColorLabels.isRequired,
  // Name of the curve this dot is attached to
  curveName: string.isRequired,
};

// A plotted curve.
export const curve = {
  points: arrayOf(arrayOf(number)).isRequired,
  // The curve fill color
  fillColor: supportedColorLabels.isRequired,
  // The curve stroke color
  strokeColor: supportedColorLabels,
  // A segment is an area under the curve.
  segments: arrayOf(shape(segment)),
  // Optional dots that sits where the marker intersects a curve
  intersectionDots: arrayOf(shape(intersectionDot)),
  // Name of the curve.
  name: string.isRequired,
};

export const tick = {
  // The node to position at the tick. Can be any element.
  label: node,
  // Float offset
  position: number.isRequired,
};

// An axis, with labels and increments
export const axis = {
  label: string,
  // Whether to draw grid lines for each tick on the axis
  gridLines: bool,
  ticks: arrayOf(shape(tick)),
};

// A single point. Ranges from 0-100 on the X and Y axis
export const point = arrayOf(number);

// Props generated from the report. Fed into top level components
// and translated into ProbabilityDistribution charts.
export const densityReportProps = {
  population: shape({
    points: arrayOf(point).isRequired,
    midpoint: number.isRequired,
  }).isRequired,
  child: shape({
    points: arrayOf(point).isRequired,
    midpoint: number.isRequired,
    riskiest: shape({
      position: number.isRequired,
      percentile: string.isRequired,
    }).isRequired,
    healthiest: shape({
      position: number.isRequired,
      percentile: string.isRequired,
    }).isRequired,
    chanceElevated: shape({
      normal: number.isRequired,
      elevated: number.isRequired,
    }).isRequired,
  }).isRequired,
  axes: shape({
    x: arrayOf(shape(tick)).isRequired,
    y: arrayOf(shape(tick)).isRequired,
  }),
  mother: shape({
    position: number.isRequired,
    percentile: string.isRequired,
  }),
  father: shape({
    position: number.isRequired,
    percentile: string.isRequired,
  }),
  dangerZone: shape({
    start: number.isRequired,
    end: number.isRequired,
  }).isRequired,
};

// Props for a single point on the range, which can be represented
// either as a percentile (prevalence) or a ratio (oddsRatio)
export const rangeMarkerProps = {
  oddsRatio: shape({
    position: number.isRequired,
    label: string.isRequired,
    value: number.isRequired,
  }),
  prevalence: shape({
    position: number.isRequired,
    label: string.isRequired,
    value: number.isRequired,
  }),
};

// Props generated from the report for range charts. Fed into
// top level components and translated into RangeChart charts
export const rangeReportProps = {
  mother: shape(rangeMarkerProps),
  father: shape(rangeMarkerProps),
  riskiest: shape(rangeMarkerProps),
  healthiest: shape(rangeMarkerProps),
  dangerZone: shape(rangeMarkerProps),
  midpoint: shape({
    prevalencePosition: number.isRequired,
    oddsRatioPosition: number.isRequired,
    label: shape.isRequired,
  }),
  prevalenceMax: oneOfType([string, number]),
};

export const dotGridReportProps = {
  mother: number,
  father: number,
  healthiest: number,
  riskiest: number,
};

// The report values for a single disease
export const diseaseReportProps = {
  density: shape(densityReportProps),
  range: shape(rangeReportProps),
  dotGrid: shape(dotGridReportProps),
  name: string.isRequired,
  chanceElevated: string.isRequired,
  chanceNormal: string.isRequired,
  key: string.isRequired,
  isElevated: bool,
};

// An entire report
export const fullReportProps = {
  elevated: arrayOf(string),
  diseases: objectOf(shape(diseaseReportProps)).isRequired,
  motherName: string.isRequired,
  fatherName: string.isRequired,
};

export const sidebarItem = {
  id: string.isRequired,
  label: string.isRequired,
};

sidebarItem.children = arrayOf(shape(sidebarItem));

export const chromosomeInfo = {
  euploid: string,
  count: string,
  modifier: string,
};

export const variant = {
  gene: string.isRequired,
  variant: string.isRequired,
};

export const panelResult = {
  details: string,
  description: string,
  call: string,
  panel: string,
  genes: arrayOf(string),
};

export const panelResultIndividual = {
  details: string,
  description: string,
  call: string,
  panel: string,
};

export const panelResultsPropType = arrayOf(shape(panelResultIndividual));

export const individualInterpretation = {
  yourResult: string,
  whatActions: string,
  panelResults: panelResultsPropType,
};

export const interpretation = {
  mother: shape(individualInterpretation),
  father: shape(individualInterpretation),
  joint: object,
};

// A list of embryos, used to render the embryo comparison page
export const singleEmbryo = {
  id: string.isRequired,
  priority: number.isRequired,
  chromosomeInfo: shape(chromosomeInfo),
  sex: oneOf(["F", "M"]).isRequred,
  morphology: string.isRequired,
  neurodevVariants: arrayOf(shape(variant)).isRequired,
  cancerVariants: arrayOf(shape(variant)),
  priorityLabel: string.isRequired,
  diseases: arrayOf(object),
};

export const embryoList = arrayOf(shape(singleEmbryo));

export const orderingInfo = {
  recipient: string.isRequired,
  attn: string.isRequired,
  phone: string.isRequired,
  fax: string.isRequired,
  npi: string.isRequired,
  reportDate: string.isRequired,
  femaleName: string,
  femaleBirthday: string,
  femaleEthnicity: string.isRequired,
  femaleSampleType: string.isRequired,
  femaleDateOfCollection: string.isRequired,
  femaleDateTested: string.isRequired,
  femaleBarcode: string.isRequired,
  femaleIndication: string.isRequired,
  maleName: string,
  maleBirthday: string,
  maleEthnicity: string.isRequired,
  maleSampleType: string.isRequired,
  maleDateOfCollection: string.isRequired,
  maleDateTested: string.isRequired,
  maleBarcode: string.isRequired,
  maleIndication: string.isRequired,
  patientName: string,
  patientBirthday: string,
  partnerName: string,
  partnerBirthday: string,
};

export const embryoComparisonReportProps = {
  embryos: embryoList,
  orderingInfo: shape(orderingInfo),
};

export const genePanelInfoProp = objectOf(
  shape({
    description: string,
  })
);
export const metadataProp = {
  genePanelInfo: genePanelInfoProp,
};
