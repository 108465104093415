import produce from "immer";
import { createStore, useStore } from "zustand";
import { createContext, useContext } from "react";
import cookie from "js-cookie";
import {
  contextAuthorizedCall,
  silentContextAuthorizedCall,
} from "../utils/auth";
import { toQueryString } from "../utils/stringUtils.mjs";
import { getInvite } from "../components/layouts/InviteBanner";
import { getPandaDocs } from "../components/user/userApi";
import {
  ERROR,
  IN_PROGRESS,
  NOT_STARTED,
  SUCCESS,
} from "../components/hooks/useAsync";

const accountContext = createContext(null);

export const Provider = accountContext.Provider;

export const useAccountStore = (selector) => {
  const store = useContext(accountContext);

  if (!store) throw new Error("Store is missing the provider");

  return useStore(store, selector);
};

export function getDefaultState() {
  return {
    accounts: [],
    account: null,
    individualOrders: [],
    individualOrder: null,
    user: null,
    documents: [],
    state: NOT_STARTED,
  };
}

export const STORED_ACCOUNT_COOKIE = "preferredOrchidAccount";
export const STORED_INDIVIDUAL_ORDER_COOKIE = "preferredOrchidIndividualOrder";

export const initializeAccountStore = (preloadedState) => {
  return createStore((set, get) => ({
    ...preloadedState,
    update: (preloadedState) => {
      if (!preloadedState || !preloadedState.user) {
        return;
      }
      const { user, accounts, account, order, individualOrders } =
        preloadedState;
      set(
        produce((state) => {
          state.accounts = accounts || [];
          state.user = user;
          state.account = account || null;
          state.order = order || null;
          state.individualOrders = individualOrders || [];
          state.documents = [];
          state.state = NOT_STARTED;
        })
      );
    },
    updateAccount: (account) => {
      const { accounts } = get();
      const index = accounts.findIndex((acc) => (acc.id = account.id));
      if (index >= 0) {
        accounts[index] = account;
      } else {
        // This shouldn't happen
        cookie.set(STORED_ACCOUNT_COOKIE, account.id);
        accounts.push(account);
      }
      set(
        produce((state) => {
          state.account = account;
          state.accounts = [...accounts];
        })
      );
    },
    setAccount: (account) => {
      if (account) {
        cookie.set(STORED_ACCOUNT_COOKIE, account.id);
      } else {
        cookie.remove(STORED_ACCOUNT_COOKIE);
      }
      set(
        produce((state) => {
          state.account = account;
        })
      );
    },
    setOrder: (order) => {
      if (order) {
        cookie.set(STORED_INDIVIDUAL_ORDER_COOKIE, order.id);
      } else {
        cookie.remove(STORED_INDIVIDUAL_ORDER_COOKIE);
      }
      set(
        produce((state) => {
          state.order = order;
        })
      );
    },
    setOrderOrAccount: (data) => {
      const { setAccount, setOrder } = get();
      const { account, order } = data;
      setAccount(account);
      setOrder(order);
    },
    accountsAndOrders: () => {
      const { accounts, individualOrders } = get();
      const res = [
        ...accounts.map((account) => ({ account })),
        ...individualOrders.map((order) => ({ order })),
      ];
      res.sort(
        (o1, o2) =>
          (o2.account?.purchaseDate || o2.order?.purchaseDate || 0) -
          (o1.account?.purchaseDate || o1.order?.purchaseDate || 0)
      );
      return res;
    },
    getLength: () => {
      const { accounts, individualOrders } = get();

      return (accounts?.length || 0) + (individualOrders?.length || 0);
    },
    setUser: (user) => {
      set(
        produce((state) => {
          state.user = user;
        })
      );
    },
    reFetch: async () => {
      const { getBytId, account } = get();
      await getBytId(account.id);
    },
    getBytId: async (id) => {
      try {
        const response = await silentContextAuthorizedCall(
          null,
          "get",
          "/user" + toQueryString({ preconception: true, withPartner: true }),
          undefined
        );
        if (!response) {
          set(
            produce((state) => {
              state.error = "no response";
            })
          );
          return;
        }
        const user = response?.user || null;
        const accounts = response?.preconceptionReports || [];
        const index = accounts.findIndex((account) => account.id === id);
        const account = index >= 0 ? accounts[index] : null;
        set(
          produce((state) => {
            state.account = account;
            state.user = user;
            state.accounts = accounts;
          })
        );
      } catch (e) {
        console.error(e);
        set(
          produce((state) => {
            state.error = e?.response?.data?.error;
          })
        );
      }
    },
    getInvite: () => {
      const { accounts, user } = get();
      return getInvite(accounts, user);
    },
    activate: async () => {
      const { account, accounts } = get();
      try {
        const { fatherOrder: prevFatherOrder, motherOrder: prevMotherOrder } =
          account;
        if (!prevFatherOrder || !prevMotherOrder) {
          set(
            produce((state) => {
              state.error = "Orders not attached";
            })
          );
          return { error: "Orders not attached" };
        }
        const [fatherOrder, motherOrder] = await Promise.all([
          await contextAuthorizedCall(
            null,
            "patch",
            `/individual_reports/${prevFatherOrder.id}/activate`
          ),
          await contextAuthorizedCall(
            null,
            "patch",
            `/individual_reports/${prevMotherOrder.id}/activate`
          ),
        ]);
        if (!fatherOrder || !motherOrder) {
          set(
            produce((state) => {
              state.error = "no response";
            })
          );
          return { error: "no response" };
        }
        const response = { ...account, fatherOrder, motherOrder };
        const index = accounts.findIndex((acc) => acc.id === account.id);
        const update = [...accounts];
        update[index] = response;
        set(
          produce((state) => {
            state.account = response;
            state.accounts = update;
          })
        );
        return { account: response };
      } catch (e) {
        console.error(e);
        set(
          produce((state) => {
            state.error = e?.response?.data?.error;
          })
        );
        return {
          error: e,
        };
      }
    },
    activateOrder: async () => {
      const { order, individualOrders } = get();
      try {
        const response = await contextAuthorizedCall(
          null,
          "patch",
          `/individual_reports/${order.id}/activate`
        );
        if (!response) {
          set(
            produce((state) => {
              state.error = "no response";
            })
          );
          return { error: "no response" };
        }
        const index = individualOrders.findIndex((acc) => acc.id === order.id);
        const update = [...individualOrders];
        update[index] = response;
        set(
          produce((state) => {
            state.order = response;
            state.individualOrders = update;
          })
        );
        return { order: response };
      } catch (e) {
        console.error(e);
        set(
          produce((state) => {
            state.error = e?.response?.data?.error;
          })
        );
        return { error: e };
      }
    },
    fetchDocuments: async () => {
      try {
        const { asUser } = get();
        set(
          produce((state) => {
            state.state = IN_PROGRESS;
          })
        );
        const context = asUser ? { query: { email: asUser } } : { query: {} };
        const res = await getPandaDocs(context);
        set(
          produce((state) => {
            state.documents = res || [];
            state.state = SUCCESS;
          })
        );
        return { documents: res };
      } catch (e) {
        console.error(e);
        set(
          produce((state) => {
            state.error = e?.response?.data?.error;
            state.state = ERROR;
          })
        );
        return { error: e };
      }
    },
  }));
};
