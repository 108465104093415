import { extendTheme } from "@chakra-ui/react";
import colors from "./components/utils/colors";

const Stepper = {
  variants: {
    results: {
      // title: {
      //   pb: 1,
      //   fontSize: "16px",
      //   lineHeight: "22px",
      //   fontWeight: "bold",
      //   color: "brand.black",
      // },
      //
      // description: {
      //   fontSize: "16px",
      //   lineHeight: "22px",
      //   color: "brand.black",
      // },

      indicator: {
        bgColor: "transparent",
        borderColor: "green600",

        "&[data-status=complete]": {
          bgColor: "green600",
          borderColor: "green600",
          borderWidth: "3px",
        },

        "&[data-status=active]": {
          bgColor: "transparent",
          borderColor: "black40",
          borderWidth: "3px",
        },

        "&[data-status=incomplete]": {
          bgColor: "transparent",
          borderColor: "black40",
          borderWidth: "3px",
        },
      },

      separator: {
        bgColor: "black40",
      },

      // icon: {
      //   borderColor: "brand.blue",
      //   bgColor: "transparent",
      // },
    },
  },
};
// Our theme overrides for Chakra.
export default extendTheme({
  // This already includes the theme colors, so we don't have to
  // extend the existing color object.
  colors,
  fonts: {
    body: "GT America, serif",
    heading: "GT Super Text, serif",
  },
  components: {
    Stepper,
  },
  Radio: {
    baseStyle: {
      color: `grey`,
      control: {
        color: "red",
        width: "1.8rem",
        height: "1.8rem",
        marginRight: "0.5rem",
        border: `1px solid ${colors.orchidGrey500}`,
        _checked: {
          backgroundColor: `${colors.orchidGrey500}`,
          border: `1px solid ${colors.orchidGrey500}`,
        },
      },
    },
  },
});
