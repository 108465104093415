import { useState, useCallback } from "react";

export const NOT_STARTED = "not started";
export const IN_PROGRESS = "in progress";
export const SUCCESS = "success";
export const ERROR = "error";
export const useAsync = (asyncOperation) => {
  const [status, setStatus] = useState(NOT_STARTED);
  const [error, setError] = useState();
  const [result, setResult] = useState();

  const execute = useCallback(
    async (params) => {
      setStatus(IN_PROGRESS);
      try {
        const response = await asyncOperation(params);
        setStatus(SUCCESS);
        setResult(response);
        setError(undefined);
        return response;
      } catch (err) {
        setError(err);
        setStatus(ERROR);
        setResult(undefined);
        return err;
      }
    },
    [asyncOperation]
  );

  const reset = useCallback(() => {
    setError(undefined);
    setResult(undefined);
    setStatus(NOT_STARTED);
  }, []);

  return {
    status,
    result,
    error,
    execute,
    reset,
  };
};
