// Public keys here ONLY. I'm pretty sure all of these variables gets
// exposed in the JS bundle.
const mapping = {
  production: {
    firebaseUrl: "https://orchid-434cb.appspot.com",
    stripePublicKey: "pk_live_G6ahwoiS8B0eiL6zdXVm6RHX00kZ1mIeOp",
    googleMapApiKey: "AIzaSyA1rhT575Ld0Xbyh0LEyKmpum7CQM3vSTs",
  },
  preview: {
    firebaseUrl: "https://orchid-434cb.appspot.com",
    stripePublicKey: "pk_live_G6ahwoiS8B0eiL6zdXVm6RHX00kZ1mIeOp",
    googleMapApiKey: "AIzaSyCFGSydqlk0c1K493oY2YqYxHpMsOb6tu8",
  },
  development: {
    firebaseUrl: "https://orchid-staging-380514-c4523.uc.r.appspot.com",
    stripePublicKey: "pk_test_mXagzTaCtI2LlavJG9qFNSvs002vKpkyfW",
    googleMapApiKey: "AIzaSyCFGSydqlk0c1K493oY2YqYxHpMsOb6tu8",
  },
  localProd: {
    firebaseUrl: "http://localhost:8080",
    stripePublicKey: "pk_test_mXagzTaCtI2LlavJG9qFNSvs002vKpkyfW",
    googleMapApiKey: "AIzaSyCFGSydqlk0c1K493oY2YqYxHpMsOb6tu8",
  },
  local: {
    firebaseUrl: "https://orchid-staging-380514-c4523.uc.r.appspot.com",
    stripePublicKey: "pk_test_mXagzTaCtI2LlavJG9qFNSvs002vKpkyfW",
    googleMapApiKey: "AIzaSyCFGSydqlk0c1K493oY2YqYxHpMsOb6tu8",
  },
  emulator: {
    firebaseUrl:
      "http://localhost:5001/orchid-staging-380514-c4523/us-central1/app",
    stripePublicKey: "pk_test_mXagzTaCtI2LlavJG9qFNSvs002vKpkyfW",
    googleMapApiKey: "AIzaSyCFGSydqlk0c1K493oY2YqYxHpMsOb6tu8",
  },
};

const getEnvMapping = (env = null) => {
  const e = env || process.env.NEXT_PUBLIC_VERCEL_ENV;
  let envMapping = mapping[e];
  if (!envMapping) {
    console.error("Could not find env mapping for ", e);
    envMapping = mapping.development;
  }

  return envMapping;
};

export function getBackendUrl(uri) {
  // e.g. if(process.env.NODE_ENV === "dev") { ... }
  if (uri[0] !== "/") {
    uri = `/${uri}`;
  }
  const { firebaseUrl } = getEnvMapping();

  return `${firebaseUrl}${uri}`;
}

export function getGoogleMapApiKey() {
  const { googleMapApiKey } = getEnvMapping();

  return googleMapApiKey;
}

export function getCookieDomain() {
  return ".orchidhealth.com";
  // switch (process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF) {
  //   case "stanford":
  //   case "master":
  //   case "all/develop":
  //     // return ".vercel.app";
  //     return undefined;
  //   default:
  //     return "localhost:3000";
  // }
}

export function getFrontendUrl() {
  return "https://portal.orchidhealth.com";
  // switch (process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF) {
  //   case "stanford":
  //   case "master":
  //   case "all/develop":
  //     // return "nextjs-three-gamma-36.vercel.app";
  //     return undefined;
  //   default:
  //     return "http://localhost:3000";
  // }
}

export function getStripePublicKey() {
  const { stripePublicKey } = getEnvMapping();
  return stripePublicKey;
}
