import React from "react";
import { MotionBox } from "../animation";
import { AnimatePresence } from "framer-motion";
import useIsLoading from "../hooks/useIsLoading";
import { bool } from "prop-types";
import LoadingSpinner from "../icons/LoadingSpinner";

function LoadingShield({ isLoading }) {
  return (
    <AnimatePresence>
      {isLoading && (
        <MotionBox
          key={isLoading}
          width="100%"
          height="100%"
          position="fixed"
          top="0"
          left="0"
          background="white"
          zIndex={11}
          initial={{
            opacity: 1,
          }}
          exit={{ opacity: 0 }}
          transition={{
            duration: 0.6,
          }}
        >
          <LoadingSpinner
            position="absolute"
            left="50%"
            transform="translateX(-50%)"
            top="45vh"
            width="50px"
            height="50px"
          />
        </MotionBox>
      )}
    </AnimatePresence>
  );
}

LoadingShield.propTypes = {
  isLoading: bool.isRequired,
};

// Shows a loading shield that hides once all images and the document.ready event have been fired.
export function PageLoadShield() {
  const isLoading = useIsLoading();

  return <LoadingShield isLoading={isLoading} />;
}

export default LoadingShield;
