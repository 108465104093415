export function formatUserData(data) {
  const { email, firstName, lastName, phone, dob, sex } = data;
  const [Month, Day, Year] = dob.split("/").map((str) => +str);
  return {
    name: {
      First: firstName,
      Last: lastName,
    },
    email,
    phone,
    sex,
    birthday: {
      Month,
      Day,
      Year,
    },
  };
}

export function isValidUser(user) {
  return (
    user &&
    isNameValid(user.name) &&
    user.sex &&
    user.email &&
    user.phone &&
    isDOBValid(user.birthday)
  );
}

export function isCompleteUser(user) {
  return Boolean(isValidUser(user) && user.mailingAddress);
}

export function healthSurveyDone(user) {
  return user && user.healthSurveyStatus === "done";
}

export function hasConsents(user) {
  return user && user.tosAccepted && user.hipaaAccepted;
}

export function isNameValid(name) {
  return name && name.First && name.Last;
}

export function isDOBValid(dob) {
  return Boolean(dob && dob.Year && dob.Month && dob.Day);
}

const ROLE_SEX = {
  father: "Male",
  mother: "Female",
};

export function roleToSex(role) {
  return ROLE_SEX[role];
}

export function sexToGender(sex) {
  return sex === "F" ? "Female" : "Male";
}

export function sexToRole(sex) {
  return sex?.toLowerCase() === "male" ? "father" : "mother";
}

export function getPartnersEmail(user, account) {
  return user?.sex === "Male" ? account?.motherEmail : account?.fatherEmail;
}

export function getPartner(user, account) {
  return user?.sex === "Male" ? account?.mother : account?.father;
}

export function getPartnersFirstName(user, account) {
  const partner = getPartner(user, account);
  if (partner && partner.name) {
    return `${partner.name.First}`;
  }

  return user.sex === "Male" ? "Mother" : "Father";
}

export function isTempUser(user) {
  if (!user || !user.email) {
    return true;
  }
  return user?.id === user?.email;
}

export function getPurchaseState({ account, order }) {
  return account
    ? getAccountPurchaseState(account)
    : getOrderPurchaseState(order);
}

export function getPurchaseTitle({ account, order }) {
  const is30x = (account?.testType || order?.testType) === "30x";
  return account
    ? `Orchid Preconception Testing ${is30x ? "- 30x" : ""}`
    : `Orchid Preconception Testing - Individual ${is30x ? "30x" : ""}`;
}

function getAccountPurchaseState(account) {
  const purchased = Boolean(account.donePilotOnboardingSteps.PURCHASED);
  const date = account.purchaseDate
    ? timestampToString(account.purchaseDate)
    : null;
  return {
    purchased,
    date,
  };
}

function getOrderPurchaseState(order) {
  const purchased = Boolean(order?.purchaseId);
  const date = order?.purchaseDate
    ? timestampToString(order.purchaseDate)
    : null;
  return {
    purchased,
    date,
  };
}

export function timestampToString(timestamp) {
  if (!timestamp) {
    return "";
  }
  const date = new Date(timestamp * 1000);
  return date.toLocaleDateString("en-US");
}
