import { Box, Link, useTheme } from "@chakra-ui/react";
import TextBox from "../text/TextBox";
import PrimaryButton from "../buttons/PrimaryButton";
import React from "react";
import PropTypes from "prop-types";
import { ROUTES } from "../user/routes";

export const getInvite = (accounts = [], user) => {
  if (!user) {
    return null;
  }
  const field = `pending${user.sex === "Male" ? "Father" : "Mother"}Accept`;
  const withInvite = accounts.filter((account) => account[field]);
  if (withInvite.length === 0) {
    return null;
  }
  const partner =
    user.sex === "Male" ? withInvite[0].mother : withInvite[0].father;

  return {
    partner,
    id: withInvite[0].id,
  };
};
function InviteBanner({ invite }) {
  const theme = useTheme();
  if (!invite) {
    return null;
  }
  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: ["column", "row"],
        alignItems: "center",
        justifyContent: "space-between",
        gap: "5px",
        padding: "6px 24px",
        mr: "24px",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.12)",
        background: "#e7eae1",
        border: `1px solid ${theme.colors.orchidGrey200}`,
        borderRadius: "5px",
        cursor: "default",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "center",
          gap: "5px",
        }}
      >
        <TextBox>{`You've been invited to be the partner on ${invite.partner.email}'s preconception report`}</TextBox>
        <TextBox
          fontSize={"12px"}
          justifyContent={"start"}
          alignItems={["start", "center"]}
          flexDirection={["column", "row"]}
          gap={"0.8ex"}
        >
          {"If this invite was in error, contact"}
          <Link href="mailto:support@orchidhealth.com">
            support@orchidhealth.com
          </Link>
        </TextBox>
      </Box>
      <Link href={ROUTES.saliva + `/invite/accept/${invite.id}`}>
        <PrimaryButton padding={"10px 20px"}>Accept invite</PrimaryButton>
      </Link>
    </Box>
  );
}

InviteBanner.propTypes = {
  invite: PropTypes.object,
};

export default InviteBanner;
