// Map of string transition key to set of Framer Motion properties
// representing that animation. If this is too much overhead, can remove &
// just tune the transitions automatically, just felt like it would be easier
// for keeping transitions in sync
const transitionsMap = {
  growInRight: ({ width }) => ({
    initial: {
      width: 0,
      overflow: "hidden",
    },
    animate: {
      width,
    },
    transition: {
      duration: 0.6,
    },
  }),
  fadeIn: () => ({
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
    },
    transition: {
      duration: 0.6,
    },
  }),
  fadeOut: () => ({
    animate: {
      opacity: 1,
    },
    exit: {
      opacity: 0,
    },
    transition: {
      duration: 0.6,
    },
  }),
  fadeInOut: () => ({
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
    },
    exit: {
      opacity: 0,
    },
    transition: {
      duration: 0.4,
    },
  }),
  growInOut: ({ height }) => ({
    initial: { opacity: 0, height: "80%" },
    animate: { opacity: 1, height },
    exit: { opacity: 0, height: "80%" },
    transition: { duration: 0.6 },
  }),
  moveFromTo: ({ startX, startY, endX, endY }) => ({
    initial: {
      top: startY,
      left: startX,
    },
    animate: { top: endY, left: endX },
    exit: {
      top: startY,
      left: startX,
    },
    transition: {
      duration: 0.6,
    },
  }),
};

export function getTransition(key, props) {
  const transitionFn = transitionsMap[key];

  if (!transitionFn) return {};

  return transitionFn(props);
}

export default transitionsMap;
