export const ROUTES = {
  saliva: "/saliva",
  signin: "/signin",
  signup: "/signup",
  healthSurvey: "/health-survey",
  reviewTerms: "/review-terms",
  pgt: "/pgt",
  results: "/results",
  step1: "/step-1",
  step2: "/step-2",
  supportPartner: "/support-partner",
  registeredPartner: "/registered-partner",
  activate: "/activate",
  activation: "/activation",
  purchase: "/purchase",
  purchase30: "/purchase-30x",
  individualPurchase: "/individual-purchase",
  individualPurchase30: "/individual-purchase-30x",
  purchased: "/purchased",
  partner: "/partner",
  provider: "/clinics",
  delivery: "/delivery",
  returnShipping: "/return-shipping",
  scheduleAppointment: "/schedule-appointment",
  father: "/father",
  mother: "/mother",
  reports: "/reports",
  individual: "/individual",
  joint: "/joint",
  embryos: "/embryos",
  embryoReports: /\/embryo-reports\/.*/g,
  accountSettings: "/account-settings",
  paymentSuccessful: "/payment-successful",
  order: "/order",
  verifyEmail: "/verify-email",
  demoRedirect: "/demo-redirect",
  inviteAccept: /\/invite\/accept\/.*/g,
  myData: "/my-data",
  imports: "/imports",
  newImport: "/new-import",
  exports: "/exports",
};
