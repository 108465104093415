import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useInterval,
} from "@chakra-ui/react";
import { getTokenFromCtx, useAuth } from "../../utils/auth";
import jwtDecode from "jwt-decode";
import firebase from "firebase/compat/app";
import { useRouter } from "next/router";
import PrimaryButton from "../buttons/PrimaryButton";

/**
 * Keep login fresh. If the user is active, refresh the token every minute. If the user's token has expired, log them
 *   out. If the user is about to be logged out. warn them.
 */
export default function LoginRefresher() {
  const [hadAuth, setHadAuth] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const activityTimeout = useRef(null);
  const auth = useAuth();
  const router = useRouter();

  useEffect(() => {
    const activityDetected = () => {
      clearTimeout(activityTimeout.current);
      setIsActive(true);
      activityTimeout.current = setTimeout(() => setIsActive(false), 60 * 1000);
    };

    window.addEventListener("mousemove", activityDetected);
    window.addEventListener("mousedown", activityDetected);
    window.addEventListener("keydown", activityDetected);
    window.addEventListener("touchstart", activityDetected);

    return () => {
      window.removeEventListener("mousemove", activityDetected);
      window.removeEventListener("mousedown", activityDetected);
      window.removeEventListener("keydown", activityDetected);
      window.removeEventListener("touchstart", activityDetected);
    };
  }, []);

  useInterval(async () => {
    if (firebase.auth().currentUser) {
      setHadAuth(true);
    } else if (!hadAuth) {
      // Don't send us to login page if we were never logged in.
      return;
    }

    if (isActive && firebase.auth().currentUser) {
      try {
        await auth.refreshBackendToken();
      } catch (err) {
        // Pass
      }
    } else {
      const token = getTokenFromCtx();
      if (hadAuth && !token) {
        await signOut();
        return;
      }
      const minutesToExp =
        (jwtDecode(token).exp - Date.now().valueOf() / 1000) / 60;
      if (minutesToExp < 0) {
        await signOut();
      } else if (minutesToExp < 10) {
        setIsModalOpen(true);
      }
    }
  }, 60 * 1000);

  const signOut = async () => {
    if (firebase.auth().currentUser) {
      await auth.signout();
    }
    setIsModalOpen(false);
    setHadAuth(false);
    const firstSegmentOfUrl = new URL(
      `https://www.example.com${router.asPath}`
    ).pathname.split("/")[1];
    if (firstSegmentOfUrl !== "signin") {
      router.push(`/signin?next=${router.asPath}`);
    }
  };

  return (
    <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Session Expiring</ModalHeader>
        <ModalBody>
          Your session is expiring soon. Would you like to stay signed in?
        </ModalBody>
        <ModalFooter>
          <Flex gap={3}>
            <Button variant="link" onClick={signOut}>
              Log Out
            </Button>
            <PrimaryButton px={4} onClick={() => setIsModalOpen(false)}>
              {/* No need to do anything beyond closing the modal. The activity monitor will refresh the token. */}
              Stay Signed In
            </PrimaryButton>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
