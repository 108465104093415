import React from "react";
import PropTypes from "prop-types";
import { Text } from "@chakra-ui/react";
function TextBox({
  children,
  color = "orchidGrey700",
  fontSize = "16px",
  px,
  alignItems = "center",
  justifyContent = "center",
  textTransform,
  onClick,
  as = "div",
  flexDirection = "column",
  ...rest
}) {
  return (
    <Text
      sx={{
        height: "100%",
        textTransform,
        display: "flex",
        flexDirection,
        alignItems,
        justifyContent,
        ...rest,
      }}
      as={as}
      color={color}
      fontSize={fontSize}
      px={px}
      onClick={onClick}
    >
      {children}
    </Text>
  );
}

TextBox.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  px: PropTypes.string,
  alignItems: PropTypes.any,
  textTransform: PropTypes.string,
  onClick: PropTypes.func,
  as: PropTypes.string,
  justifyContent: PropTypes.any,
  flexDirection: PropTypes.any,
};

export default TextBox;
