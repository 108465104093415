// Utils for formatting strings different ways
export function camelToSentence(str) {
  const spacedString = str.replace(/([a-z])([A-Z])/g, "$1 $2");
  return spacedString.charAt(0).toUpperCase() + spacedString.slice(1);
}

// -5 -> -5, 10 -> +10
export function addSign(n) {
  return n < 0 ? `${n}` : `+${n}`;
}

export function camelToTitle(string) {
  /*
   * helloThere => Hello There
   */
  let result = "";
  for (let i = 0; i < string.length; i++) {
    const currentChar = string[i];
    const nextChar = string[i + 1];

    if (
      currentChar === currentChar.toUpperCase() &&
      nextChar &&
      nextChar === nextChar.toLowerCase()
    ) {
      result += " " + currentChar;
    } else {
      result += currentChar;
    }
  }
  return result[0].toUpperCase() + result.substring(1);
}

export function toQueryString(query) {
  if (!query || !Object.keys(query).length) {
    return "";
  }
  return `?${new URLSearchParams(query).toString()}`;
}

export function writeList(list) {
  list = list.filter((item) => item); // Remove undefined
  if (list.length < 2) return list.join(", ");
  if (list.length === 2) return `${list[0]} and ${list[1]}`;
  let str = list.slice(0, list.length - 1).join(", ");
  str += `, and ${list[list.length - 1]}`;
  return str;
}

export function concatIfAllDefined(...args) {
  if (args.some((arg) => !arg)) return null;
  return args.join(" ");
}

/**
 * E.g.
 *   'hiHowAreYou' -> 'Hi How Are You'
 *   'ABCLetters' -> 'ABC Letters'
 *   'MTHFR Common A 1298 C Variant' -> 'MTHFR Common A 1298 C Variant'
 *   'MTHFR..common.A1298C.variant' -> 'MTHFR Common A1298C Variant'
 */
export function removeCamelCase(string) {
  // Replace ".." with a single space.  replace all instances.
  string = string.replace(/\.\./g, " ");

  // Replace "." with a single space.  replace all instances.
  string = string.replace(/\./g, " ");

  // If the string contains any spaces, return it as is
  if (string.includes(" ")) {
    return string;
  }

  let result = "";
  for (let i = 0; i < string.length; i++) {
    const currentChar = string[i];
    const nextChar = string[i + 1];

    if (
      currentChar === currentChar.toUpperCase() &&
      nextChar &&
      nextChar === nextChar.toLowerCase()
    ) {
      result += " " + currentChar;
    } else {
      result += currentChar;
    }
  }

  return result[0].toUpperCase() + result.substring(1);
}

export function titlefy(string) {
  /*
   * hello there => Hello There
   */
  return string.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
}