import React, { useEffect, useMemo, useState } from "react";
import PropTypes, { bool } from "prop-types";
import { useRouter } from "next/router";
import { Box, Flex, Text, Image, Link } from "@chakra-ui/react";
import { AnimatePresence } from "framer-motion";
import { MotionFlex, MotionBox } from "../animation";
import { getTransition } from "../animation/transitions";
import Logout from "../icons/Logout";
import HamburgerIcon from "../icons/HamburgerIcon";
import { useAuth } from "../../utils/auth";
import LoadingShield from "./LoadingShield";
import { pathMatches } from "../user/userRedirects";
import { ROUTES } from "../user/routes";
import StyledLink from "../buttons/StyledLink";

const allRoutes = {
  "/saliva": {
    label: "Saliva",
    subroutes: [
      ROUTES.saliva + ROUTES.activation,
      ROUTES.saliva + ROUTES.activate,
      ROUTES.saliva,
      ROUTES.saliva + ROUTES.delivery,
      ROUTES.saliva + ROUTES.purchased,
      ROUTES.saliva + ROUTES.purchase,
      ROUTES.saliva + ROUTES.returnShipping,
      ROUTES.saliva + ROUTES.scheduleAppointment,
      ROUTES.saliva + ROUTES.healthSurvey,
      ROUTES.saliva + ROUTES.healthSurvey + ROUTES.father,
      ROUTES.saliva + ROUTES.healthSurvey + ROUTES.mother,
      ROUTES.saliva + ROUTES.reviewTerms,
      ROUTES.saliva + ROUTES.results,
      ROUTES.saliva + ROUTES.reports + ROUTES.individual,
      ROUTES.saliva + ROUTES.reports + ROUTES.joint,
      ROUTES.saliva + ROUTES.partner,
      ROUTES.saliva + ROUTES.registeredPartner,
      ROUTES.saliva + ROUTES.supportPartner,
      ROUTES.saliva + ROUTES.paymentSuccessful,
      ROUTES.reports + ROUTES.individual,
      ROUTES.reports + ROUTES.joint,
      ROUTES.saliva + ROUTES.inviteAccept,
    ],
  },
  "/pgt": {
    label: "PGT",
    subroutes: [
      ROUTES.pgt + ROUTES.scheduleAppointment,
      ROUTES.pgt + ROUTES.healthSurvey,
      ROUTES.pgt + ROUTES.healthSurvey + ROUTES.father,
      ROUTES.pgt + ROUTES.healthSurvey + ROUTES.mother,
      ROUTES.pgt + ROUTES.reviewTerms,
      ROUTES.pgt + ROUTES.results,
      ROUTES.pgt + ROUTES.order,
      ROUTES.pgt + ROUTES.reports + ROUTES.embryos,
      ROUTES.embryoReports,
    ],
  },
  "/resources": {
    label: "Resources",
    subroutes: [
      "/resources",
      "/resources/what-you-will-learn-from-your-report",
      "/resources/embryo-screening-guide",
    ],
  },
  "/my-data": {
    label: "My Data",
    subroutes: [
      ROUTES.myData,
      ROUTES.myData + ROUTES.imports,
      ROUTES.myData + ROUTES.newImport,
      ROUTES.myData + ROUTES.exports,
    ],
  },
};

// A list of routes to display the loading shield whenever we begin navigation
// to them. Typically because of long load times. Because Nextjs doesn't
// transition until getServerSideProps is done, we can get awkward delays in load.
// So, by listening to the router routeChangeStart event, we display a loader
// when transitioning to these pages right away.
const routesToDisplayLoading = [
  "/reports/joint",
  "/reports/individual",
  "/reports/embryos",
  "/reports/embryo",
  ROUTES.embryoReports,
  ROUTES.saliva + "/health-survey/mother",
  ROUTES.saliva + "/health-survey/father",
  ROUTES.pgt + "/health-survey/mother",
  ROUTES.pgt + "/health-survey/father",
  ROUTES.myData + ROUTES.exports,
  ROUTES.myData + ROUTES.imports,
];

const emptyRoutes = { "/saliva": { label: "", subroutes: [] } };

function getRoutes(removeLinks) {
  return removeLinks ? emptyRoutes : allRoutes;
}

const DefaultLayout = ({
  children,
  user = null,
  useLoadingShield = false,
  isLoading = false,
  removeTopNavLinks = false,
  removeAccountLinks = false,
}) => {
  const auth = useAuth();
  const router = useRouter();
  const [routerLoading, setRouterLoading] = useState(false);
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
  const mobileMenuTransition = getTransition("fadeInOut");

  const name = useMemo(
    () =>
      (user?.name ? [user?.name.First, user?.name.Last] : [])
        .filter((f) => f)
        .join(" "),
    [user]
  );
  const routes = useMemo(
    () => getRoutes(removeTopNavLinks),
    [removeTopNavLinks]
  );
  useEffect(() => {
    const handleRouteChangeStart = (url) => {
      const uri = url.split("?")[0].split("#")[0];
      if (shouldShowLoading(uri, routesToDisplayLoading)) {
        setRouterLoading(true);
      }
    };

    const handleRouteChangeComplete = (url) => {
      const uri = url.split("?")[0].split("#")[0];
      if (shouldShowLoading(uri, routesToDisplayLoading)) {
        setRouterLoading(false);
      }
    };

    router.events.on("routeChangeStart", handleRouteChangeStart);
    router.events.on("routeChangeComplete", handleRouteChangeComplete);

    return () => {
      router.events.off("routeChangeStart", handleRouteChangeStart);
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
    };
    // eslint-disable-next-line
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuVisible(!isMobileMenuVisible);
    document.body.style.overflow = isMobileMenuVisible ? "" : "hidden";
    window.scrollTo(0, 0);
  };

  const showMyData = user?.enableDataPortal;

  return (
    <Flex direction="column" minHeight="100vh" backgroundColor="warmGray">
      <Flex
        alignItems="center"
        borderBottom="1px solid"
        borderColor="orchidGrey100"
        zIndex="12"
      >
        <Flex
          position="relative"
          justifyContent="center"
          alignItems="center"
          mx="auto"
          maxWidth="1240px"
          w="100%"
          p={["25px", "25px", "0 20px"]}
          color="transparent"
          borderColor="black"
        >
          <Link href="/">
            <Image htmlHeight="15px" w="100px" src="/logo.svg" />
          </Link>
          <Flex direction="row" ml="100px" display={["none", "none", "flex"]}>
            {Object.entries(routes)
              .filter(([url]) => showMyData || url !== "/my-data")
              .map(([url, { label, subroutes }]) => {
                return (
                  <StyledLink
                    key={label}
                    href={url}
                    isActive={
                      subroutes.findIndex((path) =>
                        pathMatches(router, path)
                      ) >= 0
                    }
                    clickable={label !== ""}
                  >
                    {label}
                  </StyledLink>
                );
              })}
          </Flex>
          <Flex direction="row" ml="auto" display={["none", "none", "flex"]}>
            {!removeAccountLinks && (
              <>
                <Link href="/account-settings" color="orchidGrey500" mr="15px">
                  {name || "My Account"}
                </Link>
                <Link
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() =>
                    auth.signout().then(() => router.push("/signin"))
                  }
                >
                  <Logout display="inline-block" height="20px" />
                </Link>
              </>
            )}
          </Flex>
          {!removeTopNavLinks && !removeAccountLinks && (
            <MotionBox
              display={["block", "block", "none"]}
              position="absolute"
              right="0"
              top="0"
              p="24px"
              onClick={toggleMobileMenu}
              initial={"closed"}
              animate={isMobileMenuVisible ? "open" : "closed"}
            >
              <HamburgerIcon />
            </MotionBox>
          )}
          <AnimatePresence>
            {isMobileMenuVisible && (
              <MotionFlex
                {...mobileMenuTransition}
                flexDirection="column"
                position="fixed"
                px="24px"
                top="67px"
                left="0"
                right="0"
                h="calc(100vh - 67px)"
                bg="warmGray"
              >
                {Object.entries(routes)
                  .filter(([url]) => showMyData || url !== "/my-data")
                  .map(([url, { label, subroutes }]) => {
                    return (
                      <StyledMobileLink
                        key={label}
                        href={url}
                        isActive={subroutes.includes(router.pathname)}
                        clickable={label !== ""}
                      >
                        {label}
                      </StyledMobileLink>
                    );
                  })}
                <Box flex="1" />
                <Flex mb="32px">
                  {!removeAccountLinks && (
                    <>
                      <Link
                        href="/account-settings"
                        color="orchidGrey400"
                        flex="1"
                        py="24px"
                        textTransform="uppercase"
                        letterSpacing="1.5px"
                        fontSize="12px"
                      >
                        Account settings
                      </Link>
                      <Link
                        onClick={() =>
                          auth.signout().then(() => router.push("/signin"))
                        }
                        color="orchidGrey400"
                        flex="1"
                        py="24px"
                        textTransform="uppercase"
                        letterSpacing="1.5px"
                        fontSize="12px"
                      >
                        Log out
                      </Link>
                    </>
                  )}
                </Flex>
                <Flex
                  color="orchidBlue"
                  py="24px"
                  borderTop="1px solid"
                  borderColor="orchidGrey200"
                >
                  <Image
                    src="/vendor/facebook.png"
                    alt="facebook icon"
                    mr="32px"
                    width="16px"
                    height="16px"
                  />
                  <Image
                    src="/vendor/twitter.png"
                    alt="twitter icon"
                    mr="32px"
                    width="16px"
                    height="16px"
                  />
                  <Image
                    src="/vendor/linkedin.png"
                    alt="linkedin icon"
                    mr="32px"
                    width="16px"
                    height="16px"
                  />
                  <Image
                    src="/vendor/instagram.png"
                    alt="instagram icon"
                    mr="32px"
                    width="16px"
                    height="16px"
                  />
                </Flex>
              </MotionFlex>
            )}
          </AnimatePresence>
        </Flex>
      </Flex>
      <Box flex={1}>
        {useLoadingShield && (
          <LoadingShield isLoading={isLoading || routerLoading} />
        )}
        {children}
      </Box>
      <Flex py="28px" borderTop="1px solid #E2E2E2">
        <Flex
          justifyContent={["center", "center", "space-between"]}
          mx="auto"
          maxWidth="1240px"
          w="100%"
          px="20px"
        >
          <Text>2024 Orchid, Inc. All rights reserved.</Text>
          <Flex direction="row" display={["none", "none", "flex"]}>
            <Text mr="50px">Terms</Text>
            <Text>Privacy Policy</Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
  user: PropTypes.object,
  isLoading: bool,
  useLoadingShield: bool,
  removeTopNavLinks: bool,
  removeAccountLinks: bool,
};

const StyledMobileLink = ({ children, clickable, isActive, ...rest }) => (
  <Link
    display="block"
    py="40px"
    color="orchidBlue"
    letterSpacing="1.5px"
    textTransform="uppercase"
    fontSize="18px"
    pointerEvents={clickable ? "all" : "none"}
    borderTop="1px solid"
    borderColor="orchidGrey200"
    _hover={{ textDecoration: "none" }}
    _last={{ borderBottom: "1px solid", borderColor: "orchidGrey200" }}
    {...rest}
  >
    {children}
  </Link>
);

StyledMobileLink.propTypes = {
  children: PropTypes.node.isRequired,
  isActive: PropTypes.bool,
  clickable: PropTypes.bool,
};

function shouldShowLoading(uri, routesToDisplayLoading) {
  for (const route of routesToDisplayLoading) {
    if (uri && uri.match(route)) return true;
  }

  return false;
}

export default DefaultLayout;
