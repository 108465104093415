import React, { createContext } from "react";
import { Box, Flex, TabPanel } from "@chakra-ui/react";
import { motion, AnimatePresence } from "framer-motion";
import { number, arrayOf, func } from "prop-types";

export const MotionBox = motion.custom(Box);
export const MotionSvg = motion.svg;
export const MotionRect = motion.rect;
export const MotionFlex = motion.custom(Flex);
export const MotionTabPanel = motion.custom(TabPanel);

export const ActiveContext = createContext(false);

/**
 * Used to render a single child from an array of children with exit
 * animations. See Framer Motion AnimatePresence docs for why this is
 * necessary: https://www.framer.com/api/motion/animate-presence/
 */
export function AnimationContainer({ active = 0, children }) {
  const Child = children[active];
  return (
    <AnimatePresence>
      <Child key={active} />
    </AnimatePresence>
  );
}

AnimationContainer.propTypes = {
  active: number.isRequired,
  children: arrayOf(func).isRequired,
};
