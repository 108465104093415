import {
  contextAuthorizedCall,
  getAuthenticatedRoute,
  silentContextAuthorizedCall,
} from "../../utils/auth";
import { toQueryString } from "../../utils/stringUtils.mjs";

export async function getUserEmbryoReports(
  context,
  requireRawDataRelease = false
) {
  const queryData = {};

  if (context.query.as_user) {
    queryData.asUser = encodeURIComponent(context.query.as_user);
  }

  if (requireRawDataRelease) {
    queryData.requireRawDataRelease = true;
  }

  return await getAuthenticatedRoute(`/embryo_reports`, context, queryData);
}

export async function getUserSurvey(context, email, role) {
  const query = {};
  if (email) {
    query.email = email;
  }
  if (role) {
    query.role = role;
  }

  return await contextAuthorizedCall(
    context,
    "get",
    "/user/surveys" + toQueryString(query),
    undefined
  );
}

export function optionsToQuery(options) {
  const query = {};
  if (options?.withEmbryos) {
    query.embryo = true;
  }
  if (options?.withSaliva) {
    query.preconception = true;
  }
  if (options?.withPartner) {
    query.withPartner = true;
  }

  if (options?.withIndividual) {
    query.individual = true;
  }

  if (options?.withIndividualReport) {
    query.withIndividualReport = true;
  }

  return query;
}

export async function getUser(context, options) {
  const query = optionsToQuery(options);

  if (!options?.skipImpersonate) {
    if (context?.query?.as_user) {
      query.asUser = context.query.as_user;
    } else if (context?.query?.email) {
      query.asUser = context.query.email;
    }
  }

  return await silentContextAuthorizedCall(
    context,
    "get",
    "/user" + toQueryString(query),
    undefined
  );
}

export async function getPreconceptionReport(context) {
  const query = {};

  if (context.query.as_user) {
    query.asUser = context.query.as_user;
  } else if (context.query.email) {
    query.asUser = context.query.email;
  }

  if (context.query.version) {
    query.version = context.query.version;
  }

  return await silentContextAuthorizedCall(
    context,
    "get",
    `/couple_reports/${context.params.id}` + toQueryString(query),
    undefined
  );
}

export async function getIndividualReport(context) {
  const query = {};

  if (context.query.version) {
    query.version = context.query.version;
  }

  return await silentContextAuthorizedCall(
    context,
    "get",
    `/individual_reports/${context.params.id}` + toQueryString(query),
    undefined
  );
}

export async function getPandaDocs(context) {
  const query = {};

  if (context?.query?.as_user) {
    query.asUser = context.query.as_user;
  } else if (context?.query?.email) {
    query.asUser = context.query.email;
  }
  if (context?.query?.report) {
    query.report = context.query.report;
  }
  return await silentContextAuthorizedCall(
    context,
    "get",
    `/user/documents` + toQueryString(query)
  );
}

export async function createPandaDocSession(id) {
  return await silentContextAuthorizedCall(
    {},
    "post",
    `/user/documents/${id}/session`
  );
}

export async function getUserUploads(context) {
  const query = {};
  if (context.query.as_user) {
    query.asUser = context.query.as_user;
  } else if (context.query.email) {
    query.asUser = context.query.email;
  }

  if (context.query.version) {
    query.version = context.query.version;
  }

  return await silentContextAuthorizedCall(
    context,
    "get",
    `/data_portal` + toQueryString(query),
    undefined
  );
}
