import React from "react";

function Logout({ ...rest }) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 11.5C8 11.2239 8.15802 11 8.35294 11L19.6471 11C19.842 11 20 11.2239 20 11.5C20 11.7761 19.842 12 19.6471 12L8.35294 12C8.15802 12 8 11.7761 8 11.5Z"
        fill="#757575"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6464 7.64645C15.8417 7.45118 16.1583 7.45118 16.3536 7.64645L20.2071 11.5L16.3536 15.3536C16.1583 15.5488 15.8417 15.5488 15.6464 15.3536C15.4512 15.1583 15.4512 14.8417 15.6464 14.6464L18.7929 11.5L15.6464 8.35355C15.4512 8.15829 15.4512 7.84171 15.6464 7.64645Z"
        fill="#757575"
      />
      <path
        d="M11 3H6C5 3 3 3.6 3 6C3 9 3 15.5 3 17C3 18.5 4 20 6 20C7.6 20 10 20 11 20"
        stroke="#757575"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default Logout;
