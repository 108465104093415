import React from "react";
import PropTypes from "prop-types";
import { Button } from "@chakra-ui/react";

const PrimaryButton = ({ children, ...rest }) => {
  return (
    <OrchidButton
      backgroundColor={"orchidBlue"}
      hooverColor={"orchidBlue80"}
      {...rest}
    >
      {children}
    </OrchidButton>
  );
};

export const SecondaryButton = ({ children, ...rest }) => {
  return (
    <OrchidButton
      {...rest}
      backgroundColor={"orchidGrey600"}
      hooverColor={"orchidGrey500"}
    >
      {children}
    </OrchidButton>
  );
};

export const OrchidButton = ({
  children,
  backgroundColor,
  hooverColor,
  padding = "15px 80px",
  ...rest
}) => {
  return (
    <Button
      type="submit"
      backgroundColor={backgroundColor}
      color="#fff"
      borderRadius="4px"
      boxShadow="0px 2px 4px rgba(0, 0, 0, 0.12)"
      height="auto"
      padding={padding}
      textTransform="uppercase"
      letterSpacing="1.5px"
      fontSize="12px"
      _hover={{
        backgroundColor: hooverColor,
      }}
      _disabled={{
        cursor: "default",
        opacity: 0.4,
        boxShadow: "none",
      }}
      variant="solid"
      cursor="pointer"
      {...rest}
    >
      {children}
    </Button>
  );
};

OrchidButton.propTypes = {
  children: PropTypes.node.isRequired,
  backgroundColor: PropTypes.string,
  hooverColor: PropTypes.string,
  padding: PropTypes.string,
};

PrimaryButton.propTypes = {
  children: PropTypes.node.isRequired,
};

SecondaryButton.propTypes = {
  children: PropTypes.node.isRequired,
};

export const StyledButton = ({
  children,
  backgroundColor,
  hooverColor,
  padding = "15px 80px",
  ...rest
}) => {
  return (
    <Button
      backgroundColor={backgroundColor}
      color="#fff"
      borderRadius="4px"
      boxShadow="0px 2px 4px rgba(0, 0, 0, 0.12)"
      height="auto"
      padding={padding}
      textTransform="uppercase"
      letterSpacing="1.5px"
      fontSize="12px"
      _hover={{
        backgroundColor: hooverColor,
      }}
      _disabled={{
        cursor: "default",
        opacity: 0.4,
        boxShadow: "none",
      }}
      variant="solid"
      cursor="pointer"
      {...rest}
    >
      {children}
    </Button>
  );
};

StyledButton.propTypes = {
  children: PropTypes.node.isRequired,
  backgroundColor: PropTypes.string,
  hooverColor: PropTypes.string,
  padding: PropTypes.string,
};

export default PrimaryButton;
