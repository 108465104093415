import {
  hasConsents,
  healthSurveyDone,
  isCompleteUser,
  isValidUser,
} from "./userDataFormater";
import { toQueryString } from "../../utils/stringUtils.mjs";
import states from "../layouts/dashboardStates";
import { ROUTES } from "./routes";
import cookies from "next-cookies";
import cookie from "js-cookie";
import { getInvite } from "../layouts/InviteBanner";
import {
  STORED_ACCOUNT_COOKIE,
  STORED_INDIVIDUAL_ORDER_COOKIE,
} from "../../state/accountStore";
export function pathMatches(router, path, exact) {
  if (!router || !router.pathname) {
    return false;
  }
  if (exact) {
    return router.pathname === path;
  }
  const found = router.pathname.match(path);
  return !(!found || found.length === 0);
}

export function purchaseValidationRedirect(user, next) {
  if (user?.user && !user.user.emailVerified) {
    return {
      redirect: {
        permanent: false,
        destination: `${ROUTES.verifyEmail}?next=${next}`,
      },
    };
  }

  return {
    props: { ...(user || {}) },
  };
}

export function validationRedirect(user, def, next, signup = ROUTES.signup) {
  if (!user) {
    return {
      redirect: {
        permanent: false,
        destination: `${ROUTES.signin}?next=${next}`,
      },
    };
  }
  if (!user.emailVerified) {
    return {
      redirect: {
        permanent: false,
        destination: `${ROUTES.verifyEmail}?next=${next}`,
      },
    };
  }
  if (user.isAdminUser) {
    return def;
  }
  if (!isValidUser(user)) {
    return {
      redirect: {
        permanent: false,
        destination: `${signup}${ROUTES.step1}`,
      },
    };
  }

  return def;
}

export function individualValidationRedirect(user, def, next, order) {
  const redirect = validationRedirect(user, null, next);

  if (redirect) {
    return redirect;
  }

  if (!order) {
    return {
      redirect: {
        permanent: false,
        destination: `${ROUTES.saliva}${ROUTES.purchase}`,
      },
    };
  }

  return def;
}

export function verifyEmailRedirect(user, def, next) {
  if (!user) {
    return {
      redirect: {
        permanent: false,
        destination: `${ROUTES.signin}`,
      },
    };
  }
  if (user.emailVerified) {
    return {
      redirect: {
        permanent: false,
        destination: next || `/`,
      },
    };
  }
  return def;
}

export function validationPreconceptionRedirect(
  user,
  def,
  next,
  account,
  asUser
) {
  const redirect = validationRedirect(
    user,
    null,
    next + (asUser ? toQueryString({ as_user: asUser }) : "")
  );

  if (redirect) {
    return redirect;
  }
  if (!account || !account.donePilotOnboardingSteps.PURCHASED) {
    return {
      redirect: {
        permanent: false,
        destination: `${ROUTES.saliva}${ROUTES.purchase}${
          asUser ? toQueryString({ as_user: asUser }) : ""
        }`,
      },
    };
  }
  if (getInvite([account], user)) {
    return {
      redirect: {
        permanent: false,
        destination: `${ROUTES.saliva}${ROUTES.purchased}${
          asUser ? toQueryString({ as_user: asUser }) : ""
        }`,
      },
    };
  }

  return def;
}

export function validationSingleRedirect(
  user,
  def,
  next,
  reports,
  testWorkups,
  asUser,
  root = ROUTES.pgt
) {
  const redirect = validationRedirect(
    user,
    null,
    next + (asUser ? toQueryString({ as_user: asUser }) : ""),
    root
  );

  if (redirect) {
    return redirect;
  }

  if (
    (!reports || !reports.length) &&
    (!testWorkups || testWorkups.length === 0)
  ) {
    return {
      redirect: {
        permanent: false,
        destination:
          `${root}${ROUTES.order}` +
          (asUser ? toQueryString({ as_user: asUser }) : ""),
      },
    };
  }

  return def;
}

export function surveyValidationRedirect(
  user,
  report,
  def,
  next,
  account,
  asUser
) {
  const redirect = validationPreconceptionRedirect(
    user,
    null,
    next,
    account,
    asUser
  );

  if (redirect) {
    return redirect;
  }

  if (report || healthSurveyDone(user)) {
    return def;
  }

  return {
    redirect: {
      permanent: false,
      destination:
        `${ROUTES.saliva + ROUTES.healthSurvey}` +
        (asUser ? toQueryString({ as_user: asUser }) : ""),
    },
  };
}

export function rootRedirect(user, embryoReports, individualOrders, context) {
  if (!user) {
    return {
      redirect: {
        permanent: false,
        destination: `${ROUTES.signin}?next=/`,
      },
    };
  }
  if (!user.emailVerified) {
    return {
      redirect: {
        permanent: false,
        destination: ROUTES.verifyEmail,
      },
    };
  }

  if (user.clinics) {
    return {
      redirect: {
        permanent: false,
        destination: `${ROUTES.provider}`,
      },
    };
  }

  if (embryoReports && embryoReports.length > 0) {
    return {
      redirect: {
        permanent: false,
        destination: `${ROUTES.pgt}`,
      },
    };
  }

  if (individualOrders && individualOrders.length > 0) {
    const order = preferIndividualOrder(individualOrders, context);
    return individualRedirect(user, order);
  }

  return {
    redirect: {
      permanent: false,
      destination: `${ROUTES.saliva}`,
    },
  };
}

export function pgtRedirect(user, reports, testWorkups, root = ROUTES.pgt) {
  if (!user) {
    return {
      redirect: {
        permanent: false,
        destination: `${ROUTES.signin}?next=${root}`,
      },
    };
  }
  if (!user.emailVerified) {
    return {
      redirect: {
        permanent: false,
        destination: ROUTES.verifyEmail,
      },
    };
  }
  if (!isCompleteUser(user)) {
    return {
      redirect: {
        permanent: false,
        destination: `${root}${ROUTES.step1}`,
      },
    };
  }
  if (
    (!reports || reports.length === 0) &&
    (!testWorkups || testWorkups.length === 0)
  ) {
    return {
      redirect: {
        permanent: false,
        destination: `${root}${ROUTES.order}`,
      },
    };
  }

  if (!hasConsents(user)) {
    return {
      redirect: {
        permanent: false,
        destination: `${root}${ROUTES.reviewTerms}`,
      },
    };
  }

  if (testWorkups && testWorkups.length > 0) {
    return {
      redirect: {
        permanent: false,
        destination: `${root}${ROUTES.results}`,
      },
    };
  }

  return {
    redirect: {
      permanent: false,
      destination: `${root}${ROUTES.results}`,
    },
  };
}

export function individualRedirect(user, order, root = ROUTES.saliva) {
  if (!user) {
    return {
      redirect: {
        permanent: false,
        destination: `${ROUTES.signin}?next=${root}`,
      },
    };
  }
  if (!isCompleteUser(user)) {
    return {
      redirect: {
        permanent: false,
        destination: `${ROUTES.signup}${ROUTES.step1}`,
      },
    };
  }
  if (!order) {
    return {
      redirect: {
        permanent: false,
        destination: `${root}${ROUTES.purchase}`,
      },
    };
  }

  if (!hasConsents(user)) {
    return {
      redirect: {
        permanent: false,
        destination: `${root}${ROUTES.reviewTerms}`,
      },
    };
  }

  if (!order.activated) {
    return {
      redirect: {
        permanent: false,
        destination: `${root}${ROUTES.activation}`,
      },
    };
  }

  if (healthSurveyDone(user)) {
    return {
      redirect: {
        permanent: false,
        destination: `${root}${ROUTES.results}`,
      },
    };
  }

  return {
    redirect: {
      permanent: false,
      destination: `${root}${ROUTES.healthSurvey}`,
    },
  };
}

export function selectedOrderOrAccount(accounts, orders, context) {
  let id;
  let idString;
  if (context) {
    id =
      cookies(context)[STORED_ACCOUNT_COOKIE] ||
      cookies(context)[STORED_INDIVIDUAL_ORDER_COOKIE];
    idString = context.params?.id || context.query?.id;
  } else {
    id =
      cookie.get(STORED_ACCOUNT_COOKIE) ||
      cookie.get(STORED_INDIVIDUAL_ORDER_COOKIE);
  }
  if (idString) {
    let index = accounts
      ? accounts.findIndex((account) => account.id?.startsWith(idString))
      : -1;
    if (index >= 0) {
      return { account: accounts[index], order: null };
    }

    index = orders
      ? orders.findIndex((order) => order.id?.startsWith(idString))
      : -1;
    if (index >= 0) {
      return { account: null, order: orders[index] };
    }
  }

  if (id) {
    let index = accounts
      ? accounts.findIndex((account) => account.id === id)
      : -1;
    if (index >= 0) {
      return { account: accounts[index], order: null };
    }

    index = orders ? orders.findIndex((order) => order.id === id) : -1;
    if (index >= 0) {
      return { account: null, order: orders[index] };
    }
  }
  return { account: null, order: null };
}

export function preferOrderOrAccount(accounts, orders, context) {
  const res = selectedOrderOrAccount(accounts, orders, context);
  if (res.order || res.account) {
    return res;
  }
  res.account = preferAccount(accounts, context);
  if (res.account) {
    return res;
  }
  res.order = preferIndividualOrder(orders, context);

  return res;
}

export function preferAccount(accounts, context) {
  if (!Array.isArray(accounts) || accounts.length === 0) {
    return null;
  }
  let id;
  let idString;
  if (context) {
    id = cookies(context)[STORED_ACCOUNT_COOKIE];
    idString = context.params?.id || context.query?.id;
  } else {
    id = cookie.get(STORED_ACCOUNT_COOKIE);
  }
  if (idString) {
    const index = accounts.findIndex((account) =>
      account.id?.startsWith(idString)
    );
    if (index >= 0) {
      return accounts[index];
    }
  }

  if (id) {
    const index = accounts.findIndex((account) => account.id === id);
    if (index >= 0) {
      return accounts[index];
    }
  }
  accounts.sort((a1, a2) => {
    if (
      (a1.pendingFatherAccept || a1.pendingMotherAccept) !==
      (a2.pendingFatherAccept || a2.pendingMotherAccept)
    ) {
      return a1.pendingFatherAccept || a1.pendingMotherAccept ? -1 : 1;
    }
    if (
      a1.donePilotOnboardingSteps.PARTNER_INFO !==
      a2.donePilotOnboardingSteps.PARTNER_INFO
    ) {
      return a1.donePilotOnboardingSteps.PARTNER_INFO ? -1 : 1;
    }

    if (
      a1.donePilotOnboardingSteps.PURCHASED !==
      a2.donePilotOnboardingSteps.PURCHASED
    ) {
      return a1.donePilotOnboardingSteps.PURCHASED ? -1 : 1;
    }

    if (
      a1.donePilotOnboardingSteps.ACTIVATED !==
      a2.donePilotOnboardingSteps.ACTIVATED
    ) {
      return a1.donePilotOnboardingSteps.ACTIVATED ? -1 : 1;
    }

    if (a1.reportReady !== a2.reportReady) {
      return a1.reportReady ? 1 : -1;
    }
  });

  return accounts[0];
}

export function preferIndividualOrder(orders, context) {
  if (!Array.isArray(orders) || orders.length === 0) {
    return null;
  }
  let id;
  let idString;
  if (context) {
    id = cookies(context)[STORED_INDIVIDUAL_ORDER_COOKIE];
    idString = context.params?.id || context.query?.id;
  } else {
    id = cookie.get(STORED_INDIVIDUAL_ORDER_COOKIE);
  }
  if (idString) {
    const index = orders.findIndex((order) => order.id?.startsWith(idString));
    if (index >= 0) {
      return orders[index];
    }
  }

  if (id) {
    const index = orders.findIndex((order) => order.id === id);
    if (index >= 0) {
      return orders[index];
    }
  }
  orders.sort((o1, o2) => o2.created - o1.created);

  return orders[0];
}

export function salivaRedirect(user, accounts, context) {
  if (!user) {
    return {
      redirect: {
        permanent: false,
        destination: `${ROUTES.signin}?next=${ROUTES.saliva}`,
      },
    };
  }
  if (!user.emailVerified) {
    return {
      redirect: {
        permanent: false,
        destination: ROUTES.verifyEmail,
      },
    };
  }
  if (!isCompleteUser(user)) {
    return {
      redirect: {
        permanent: false,
        destination: `${ROUTES.signup}${ROUTES.step1}`,
      },
    };
  }

  if (!hasConsents(user)) {
    return {
      redirect: {
        permanent: false,
        destination: `${ROUTES.saliva}${ROUTES.reviewTerms}`,
      },
    };
  }
  const account = preferAccount(accounts, context);
  if (
    account &&
    (account.currentPilotOnboardingStep === "PERSONAL_INFO" ||
      account.currentPilotOnboardingStep === "PARTNER_INFO")
  ) {
    return {
      redirect: {
        permanent: false,
        destination: `${ROUTES.saliva}${ROUTES.partner}`,
      },
    };
  }

  if (!account || !account.donePilotOnboardingSteps.PURCHASED) {
    return {
      redirect: {
        permanent: false,
        destination: `${ROUTES.saliva}${ROUTES.purchase}`,
      },
    };
  }
  return {
    redirect: {
      permanent: false,
      destination: getStepRedirect(account.currentPilotOnboardingStep),
    },
  };
}

function getStepRedirect(step) {
  let newStep = step === "PERSONAL_INFO" ? "PARTNER_INFO" : step;
  if (step === "HEALTH_SURVEY") {
    newStep = "RESULTS";
  }
  return states[newStep]?.route || ROUTES.saliva + ROUTES.reviewTerms;
}
