import React from "react";
import PropTypes from "prop-types";
import { Link as ChakraLink } from "@chakra-ui/react";

const Link = ({ children, ...rest }) => (
  <ChakraLink {...rest}>{children}</ChakraLink>
);

Link.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Link;
