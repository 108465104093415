/**
 * This file contains the mapping between a disease and the tuned
 * configuration for rendering appropriate density maps.
 *
 * Any rendering config that varies per-disease but not per-individual
 * should be stored here.
 */

const defaultConfig = {
  name: "Unknown",
  lowerName: "unknown",
  numberOfBuckets: 36,
  smoothing: 1,
  paddingBuckets: 3,
  xPercentiles: [10, 30, 50, 70, 90],
  yTicks: 4,
};

export const perDiseaseConfig = {
  brc: { ...defaultConfig, name: "Breast Cancer", lowerName: "breast cancer" },
  bmi: {
    ...defaultConfig,
    name: "Class III Obesity",
    lowerName: "class III obesity",
  },
  prc: {
    ...defaultConfig,
    name: "Prostate Cancer",
    lowerName: "prostate cancer",
  },
  t2d: {
    ...defaultConfig,
    name: "Type 2 Diabetes",
    lowerName: "type 2 diabetes",
  },
  cad: {
    ...defaultConfig,
    name: "Coronary Artery Disease",
    lowerName: "coronary artery disease",
  },
  afb: {
    ...defaultConfig,
    name: "Atrial Fibrillation",
    lowerName: "atrial fibrillation",
  },
  ibd: {
    ...defaultConfig,
    name: "Inflammatory Bowel Disease",
    lowerName: "inflammatory bowel disease",
  },
  scz: {
    ...defaultConfig,
    name: "Schizophrenia",
    lowerName: "schizophrenia",
  },
  isc: {
    ...defaultConfig,
    name: "Ischemic Stroke",
    lowerName: "ischemic stroke",
  },
  alz: {
    ...defaultConfig,
    name: "Alzheimer's Disease",
    lowerName: "Alzheimer's disease",
  },
  t1d: {
    ...defaultConfig,
    name: "Type 1 Diabetes",
    lowerName: "type 1 diabetes",
  },
  bipolar: {
    ...defaultConfig,
    name: "Bipolar Disorder",
    lowerName: "bipolar",
  },
  celiac: {
    ...defaultConfig,
    name: "Celiac Disease",
    lowerName: "celiac disease",
  },
  parkinsons: {
    ...defaultConfig,
    name: "Parkinson’s Disease",
    lowerName: "Parkinson's disease",
  },
};

export function getDiseaseConfig(key) {
  return perDiseaseConfig[key] || defaultConfig;
}
