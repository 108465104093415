import React from "react";
import { elementType, object } from "prop-types";
import Head from "next/head";
import { ChakraProvider, CSSReset } from "@chakra-ui/react";
import "../styles/globals.css";
import { ProvideAuth } from "../utils/auth";
import customTheme from "../theme";
import ErrorBoundary from "../components/ErrorBoundary";
import LoginRefresher from "../components/authorization/LoginRefresher";

function MyApp({ Component, pageProps, err }) {
  return (
    <ProvideAuth>
      <Head>
        <link rel="manifest" href="/manifest.json" />
        <link rel="icon" type="image/png" href="/favicon.png" />
        <title>{`Orchid Portal`}</title>
        <meta
          name="viewport"
          content="initial-scale=1.0, minimum-scale=1.0, width=device-width"
        />
      </Head>
      {process.env.NEXT_PUBLIC_VERCEL_ENV === "production" && (
        <script
          defer
          data-domain="orchidhealth.com"
          src="https://analytics.orchidhealth.tools/js/script.tagged-events.js"
        ></script>
      )}
      <ChakraProvider theme={customTheme} cssVarsRoot="body">
        <CSSReset />
        {/* Workaround for https://github.com/vercel/next.js/issues/8592 */}
        <ErrorBoundary useLayout={true}>
          <LoginRefresher />
          <Component {...pageProps} err={err} />
        </ErrorBoundary>
      </ChakraProvider>
    </ProvideAuth>
  );
}

MyApp.propTypes = {
  Component: elementType,
  pageProps: object,
  err: object,
};

export default MyApp;
