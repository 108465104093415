import React from "react";
import { bool, node, object, string } from "prop-types";
import ErrorPage from "./ErrorPage";
import logger from "../utils/logger";

// Code mostly taken from https://reactjs.org/docs/error-boundaries.html
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // We might not even need this?
    logger.error(error);
    logger.error(errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <ErrorPage
          heading="Ooooooops... An unexpected error has occurred..."
          text="We are sorry but we have an encountered an unexpected error. Please try again soon."
          useLayout={this.props.useLayout}
          user={this.props.user}
          additionalErrors={
            this.props.validationError
              ? [this.props.validationError]
              : undefined
          }
        />
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: node,
  user: object,
  validationError: string,
  useLayout: bool,
};

export default ErrorBoundary;
