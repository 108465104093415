import { useEffect, useState } from "react";

// Some good old-fashioned DOM listening!
function useIsLoading() {
  const [documentReady, setDocumentReady] = useState(false);
  const [imagesReady, setImagesReady] = useState(false);

  useEffect(() => {
    const docReady = document.readyState === "complete";

    if (docReady) {
      setDocumentReady(true);
    } else {
      document.onreadystatechange = () => {
        if (document.readyState === "complete") {
          setDocumentReady(true);
        }
      };
    }

    async function awaitImageLoad() {
      await Promise.all(
        Array.from(document.images)
          .filter((img) => !img.complete)
          .map(
            (img) =>
              new Promise((resolve) => {
                img.onload = img.onerror = resolve;
              })
          )
      );

      setImagesReady(true);
    }

    awaitImageLoad();

    function cleanup() {
      document.onreadystatechange = null;
      for (const image of document.images) {
        image.onload = image.onerror = null;
      }
    }

    return cleanup;
  }, []);

  return !(documentReady && imagesReady);
}

export default useIsLoading;
