import { theme } from "@chakra-ui/react";

// Houses our map of color names to RGBA codes. Will also be applied to the Chakra
// theme. Used by any component that uses colors.
// We use RGBA so we can specify alpha in the color, since many of our graphs render
// opacity.
const colorMap = {
  ...theme.colors,
  black08: "rgba(0, 0, 0, 0.08)",
  black20: "rgba(0, 0, 0, 0.2)",
  black40: "rgba(0, 0, 0, 0.4)",
  black60: "rgba(0, 0, 0, 0.6)",
  black70: "rgba(0, 0, 0, 0.7)",
  black80: "rgba(0, 0, 0, 0.8)",
  red: "#DD4E45",
  redAlpha: "rgba(221, 78, 69, 0.5)",
  redAlphaLight: "rgba(221, 78, 69, 0.1)",
  redLight: "rgb(242, 220, 218)",
  green: "#3A8370",
  green500: "#3A8370",
  green600: "#37806E",
  green400: "#49A48C",
  green300: "#4EB297",
  greenAlpha: "rgba(58, 131, 112, 0.5)",
  greenAlphaLight: "rgb(210, 224, 219, 0.7)",
  greenLight: "rgb(210, 224, 219)",
  greyLight: "#E2E2E2",
  greyLightAlpha: "rgba(0, 0, 0, 0.08)",
  greyDarkAlpha: "rgba(53, 55, 78, 0.5)",
  // ID'd by "bg" in Figma
  greyscale1: "#F9F9F9",
  greyscale2: "#E9ECEF",
  greyscale5: "#ADB5BD",
  greyscale7: "#495057",
  greyscale8: "#343A40",
  orchidBlack: "#171616",
  orchidGrey700: "#686868",
  orchidGrey600: "#545454",
  orchidGrey400: "#8F8F8F",
  orchidGrey500: "#757575",
  orchidGrey200: "#E2E2E2",
  orchidGrey100: "#EEEEEE",
  orchidGrey50: "#F9F9F9", // OR #EFEFEF?
  orchidBlue: "#35374E",
  orchidBlue400: "#535569",
  orchidBlue500: "#35374E",
  orchidBlue80: "rgba(53, 55, 78, 0.8)",
  orchidBlue40: "rgba(53, 55, 78, 0.4)",
  orchidBlue30: "rgba(53, 55, 78, 0.3)",
  orchidPurple: "#A87C96",
  orchidYellow: "#FBAE4B",
  orchidYellow100: "#FFF8ED",
  white56: "rgba(255, 255, 255, 0.56)",
  white16: "rgba(255, 255, 255, 0.16)",
  warmGray: "#F8F8F6",
  reportMale: "#E9EEF4",
  babyBlueAlpha: "rgba(155, 211, 221, 0.8)",
  babyBlueAlphaLight: "rgba(155, 211, 221, 0.4)",
  babyBlueAlphaDark: "rgba(50, 240, 255, 1)",
  reportFemale: "#F9EEE9",
  babyPinkAlpha: "rgba(242, 172, 185, 0.8)",
  babyPinkAlphaLight: "rgba(242, 172, 185, 0.4)",
  tanLight: "#F3EDE6",
  tan: "#CFBCAB",
  systemError: "#BC1A10",
  brownEyes: "#412b15",
  greenEyes: "#717a59",
  blueEyes: "#80a3ea",
  darkBlue: "#0076be",
  blonde: "#f0e39e",
  darkBrown: "#654321",
  lightBrown: "#b59472",
  redHair: "#e88764",
  complete: {
    400: "#3A8370",
    500: "#3A8370",
    300: "#37806E",
    200: "#49A48C",
    100: "#4EB297",
    900: "rgba(58, 131, 112, 0.5)",
  },
  black: {
    60: "rgb(153, 153, 153)",
    900: "#000",
  },
};

export default colorMap;

export function getColor(name) {
  return colorMap[name];
}

export function getColorFromGender(gender) {
  if (!gender) return colorMap.orchidBlue;

  if (gender === "male") {
    return colorMap.reportMale;
  }

  return colorMap.reportFemale;
}
