import { Button, Flex, Link, Text } from "@chakra-ui/react";
import PropTypes from "prop-types";
import React from "react";
import { ArrowBackIcon, ExternalLinkIcon } from "@chakra-ui/icons";

export function BackLink({ children, url }) {
  return (
    <Flex w={"100%"}>
      <StyledLink narrow href={url} clickable={true}>
        <Flex direction="row" alignItems={"center"}>
          <ArrowBackIcon />
          <Text ml={2} textAlign="center">
            {children}
          </Text>
        </Flex>
      </StyledLink>
    </Flex>
  );
}

BackLink.propTypes = {
  children: PropTypes.node,
  url: PropTypes.string.isRequired,
};

export function ButtonLink({ children, url }) {
  return (
    <Link href={url} target={"_blank"} rel="noopener noreferrer">
      <Button>
        <Text mr={2}>{children}</Text>
        <ExternalLinkIcon />
      </Button>
    </Link>
  );
}
ButtonLink.propTypes = {
  children: PropTypes.node,
  url: PropTypes.string.isRequired,
};
const StyledLink = ({ children, isActive, clickable, narrow, ...rest }) => (
  <Link
    p={narrow ? "5px" : "41px 16px"}
    color="orchidBlue"
    letterSpacing="1.5px"
    textTransform="uppercase"
    borderBottom="3px solid"
    borderColor={isActive ? "orchidBlue500" : "transparent"}
    pointerEvents={clickable ? "all" : "none"}
    style={{ textDecoration: "none" }}
    {...rest}
  >
    {children}
  </Link>
);

StyledLink.propTypes = {
  children: PropTypes.node.isRequired,
  isActive: PropTypes.bool,
  clickable: PropTypes.bool,
  narrow: PropTypes.bool,
};

export default StyledLink;
