import React from "react";
import { Heading } from "@chakra-ui/react";
import colors from "../utils/colors";

function Title({ ...props }) {
  return (
    <Heading
      as="span"
      fontFamily="GT Super Text"
      fontWeight={300}
      fontSize={{ base: "28px", sm: "32px", xl: "60px" }}
      lineHeight="1.15"
      letterSpacing="-2px"
      color={colors.orchidBlue}
      {...props}
    />
  );
}

Title.propTypes = {};

export default Title;
