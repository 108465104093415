import React from "react";
import { Box } from "@chakra-ui/react";
import colors from "../utils/colors";
import { number, string, object, oneOfType } from "prop-types";
import { supportedColorValues } from "../utils/propTypes";
import { keyframes } from "@emotion/react";

function LoadingSpinner({
  numLeaves = 10,
  width = "80px",
  height = "80px",
  color = colors.orchidBlue,
  loopTime = 1,
  ...rest
}) {
  const leaves = [];

  for (let i = 0; i < numLeaves; i++) {
    leaves.push(
      <SpinnerLeaf
        color={color}
        offset={i * (360 / numLeaves)}
        delay={i * (loopTime / numLeaves)}
        key={i}
      />
    );
  }
  return (
    <Box position="relative" width={width} height={height} {...rest}>
      {leaves}
    </Box>
  );
}

LoadingSpinner.propTypes = {
  numLeaves: number,
  width: oneOfType([string, object]),
  height: oneOfType([string, object]),
  color: supportedColorValues,
  loopTime: number,
};

const animation = keyframes`
  from {
    opacity: 1
  }
  to {
    opacity: 0
  }
`;

function SpinnerLeaf({ color, offset, delay }) {
  return (
    <Box
      width="100%"
      height="100%"
      position="absolute"
      top="0"
      left="0"
      transform={`rotate(${offset}deg)`}
    >
      <Box
        position="relative"
        left="35%"
        width="30%"
        height="30%"
        borderRadius="50%"
        backgroundColor={color}
        transform="scale(1, 1.8)"
        opacity="0"
        animation={`${animation} 1s ${delay}s linear infinite`}
      />
    </Box>
  );
}

SpinnerLeaf.propTypes = {
  color: supportedColorValues,
  offset: number,
  delay: number,
};

export default LoadingSpinner;
