import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";
import { arrayOf, bool, node, object, string } from "prop-types";
import { Flex, Image, Box, Text } from "@chakra-ui/react";
import DefaultLayout from "./layouts/DefaultLayout";
import PrimaryButton from "./buttons/PrimaryButton";
import Link from "../components/Link";
import Title from "./typography/Title";
import colors from "./utils/colors";
import logger from "../utils/logger";

export function ErrorText({ text }) {
  return (
    <Text margin="24px 0">
      {text}{" "}
      <Link href="mailto:support@orchidhealth.com">
        Click here to report an issue
      </Link>
      .
    </Text>
  );
}

ErrorText.propTypes = {
  text: string.isRequired,
};

export function ErrorContent({
  heading,
  text,
  redirectLink,
  redirectText,
  user = null,
  textView = null,
  noButton,
}) {
  return (
    <Flex alignItems="center" flexWrap="wrap" maxWidth="1400px" margin="0 auto">
      <Image
        order={{ base: 0, md: 1 }}
        maxWidth={{ base: "100%", md: "50%" }}
        margin="0 auto"
        src="/images/error.png"
        alt="Line illustration of mother, father, and baby"
        htmlWidth={600}
        htmlHeight={600}
        height="auto"
      />
      <Box
        order={{ base: 1, md: 0 }}
        width={{ base: "100%", md: "50%" }}
        padding={{ base: "24px", md: "0" }}
      >
        <Box maxWidth="400px" margin="0 auto">
          {user?.isAdminUser ? (
            <AdminContent user={user} />
          ) : (
            <>
              <Title
                as="h1"
                color="orchidBlue"
                paddingBottom="24px"
                borderBottom={`1px solid ${colors.orchidGrey100}`}
                fontSize="40px"
              >
                {heading}
              </Title>
              {textView || <ErrorText text={text} />}
              {noButton ? null : (
                <Link href={redirectLink || "/"}>
                  <PrimaryButton width="100%">
                    {redirectText || "Homepage"}
                  </PrimaryButton>
                </Link>
              )}
            </>
          )}
        </Box>
      </Box>
    </Flex>
  );
}

function ErrorPage({
  heading,
  text,
  redirectLink,
  redirectText,
  removeTopNavLinks,
  user = null,
  useLayout = true,
  additionalErrors = [],
  textView = null,
}) {
  const Layout = useLayout ? DefaultLayout : React.Fragment;
  const layoutProps = useLayout ? { removeTopNavLinks } : {};

  // Usually, the additionalErrors array contains the couple/embryo report
  // validation errors. This doesn't feel like the best spot to log them
  // but I'll put them here for now.
  useEffect(() => {
    additionalErrors.forEach((errorMessage) => {
      logger.error(errorMessage);
    });
  }, [additionalErrors]);

  return (
    <Layout {...layoutProps}>
      <ErrorContent
        heading={heading}
        text={text}
        user={user}
        redirectLink={redirectLink}
        redirectText={redirectText}
        textView={textView}
      />
    </Layout>
  );
}

ErrorContent.propTypes = {
  heading: string.isRequired,
  text: string,
  redirectLink: string,
  redirectText: string,
  user: object,
  textView: node,
  noButton: bool,
};

export function DefaultMessage() {
  return (
    <Text margin="24px 0">
      {"Please contact us at"}
      <Link ml={1} href="mailto:support@orchidhealth.com">
        support@orchidhealth.com
      </Link>
      {" ."}
    </Text>
  );
}

const AdminContent = ({ user }) => {
  const router = useRouter();
  const [countDown, setCountDown] = useState(15);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown((c) => {
        if (c-- <= 0) {
          clearInterval(interval);
        }
        return c;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <Title
        as="h1"
        color="orchidBlue"
        paddingBottom="24px"
        borderBottom={`1px solid ${colors.orchidGrey100}`}
        fontSize="40px"
      >
        Server error
      </Title>
      <Text my={4} fontWeight="bold">
        Super secret debug page enabled because you are logged in as an admin
        user.
      </Text>
      <Text my={4}>
        Logged in user:
        <br />
        <span style={{ fontWeight: "bold" }}>{user.email}</span>
      </Text>
      {router?.query?.email && (
        <Text my={4}>
          Requesting the report of the following user:
          <br />
          <span style={{ fontWeight: "bold" }}>{router?.query?.email}</span>
        </Text>
      )}
      {countDown <= 0 ? (
        <Text my={4} color="orchidBlue" textDecoration="underline">
          Web cloud logs are disabled for now
        </Text>
      ) : (
        <Text my={4} color="orchidBlue">
          Waiting for debug logs to be available in {countDown} seconds...
        </Text>
      )}
    </>
  );
};

AdminContent.propTypes = {
  user: object,
};

ErrorPage.propTypes = {
  heading: string.isRequired,
  text: string,
  redirectLink: string,
  redirectText: string,
  removeTopNavLinks: bool,
  user: object,
  useLayout: bool,
  additionalErrors: arrayOf(string),
  textView: node,
};

export default ErrorPage;
